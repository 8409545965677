<script setup>

import ChangePassword from '@/components/ChangePassword.vue'
</script>

<template>
  <ChangePassword ref="password_change"/>
</template>

<script>
import HelpAccount from '@/components/ChangePassword.vue';

export default{
    components: {
        ChangePassword
    },
    mounted(){
        this.$refs.password_change.open();
        this.$refs.password_change.setModal(false);
    }
  }
</script>
